import * as React from "react"
import Contacto from "../components/contacto"
import Layout from "../components/layout/en"



// markup
const ContactoPage = (props) => {

  return (
    <Layout location={props.location} pageId="contact" metaTitle="Contact us | Crequs">
      <Contacto privacy_link="/en/privacy-policy" />
    </Layout>
  )
}

export default ContactoPage


